import React from "react";
import { isMobile } from 'react-device-detect';


//class
import '../index.css';
import AvalibleLink from "../element/AvalibleLink";
import ImageSlider from "../element/ImageSlider";
 


function AppDetails({index, projectData }) {

  
  return(
    <div className="screen_container">

      {/* //one item  */}
      <div className={`w-full flex ${isMobile ? "flex-col" : `h-screen ${index % 2 === 1 ? "flex-row-rev" : "flex-row"}`}`}>

        {/* //left */}
        <div className={`flex h-full flex-col items-center justify-center ${isMobile ? "w-full h-screen" : projectData.project_type === "web" ? "w-1/3" : "w-1/2"}`}>

          {/* //app icon and title */}
          <img src={projectData.app_icon} className="w-32 h-32 rounded-3xl"></img>
          <div className="my-7 text-3xl  font-bold">{projectData.app_name}</div>

          {/* //short des */}
          <div className={`${isMobile ? "w-full px-4" : "w-3/5"} my-3 text-lg  m-auto text-center`}>{projectData.short_des}</div>

          {/* //downlod button */}
          <AvalibleLink type={projectData.avalible_on} link={projectData.p_links} name={projectData.app_name}/>

        </div> 

        {/* //right */}
        <div className={`flex h-full flex-col items-center justify-center ${isMobile ? "w-full h-[50vh]" : projectData.project_type === "web" ? "w-2/3" : "w-1/2"}`}>
            <ImageSlider images={projectData.appScreenshots}/>
        </div> 

      </div>
      
    </div>
  )
}


export default AppDetails;