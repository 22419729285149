import React from 'react';
import { FaCode } from "react-icons/fa6";


const SplashPage = () => {
  return (
    <div className="h-screen w-full fixed flex flex-col justify-between items-center bg-fj">

      <div></div>
      <div> <FaCode className="w-18 h-18 cursor-pointer m-3 " style={{ color: 'white' }}/> </div>
      <h1 className="p-2.5 text-base py-5 mb-4 fdfdsfs3" style={{ color: 'white' }}>Please wait...</h1>

    </div>
  );
};

export default SplashPage;
