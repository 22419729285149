import React, { useRef, useState , useEffect, useContext} from "react";
import axios from "axios";
import { useNavigate, useLocation, json } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { ThemeContext } from '../ThemeProvider';


import '../index.css';
import Header from "../page_h/Header";
import AppDetails from "../page_h/AppDetails";
import SplashPage from "./SplashPage";
import { HomeURL, BackendCall, DETAILS } from "../s_data/file";

 
import Design1 from '../images/test_img_1.svg';
import Design2 from '../images/test_img_2.png';

//icon
import { SiLeetcode } from "react-icons/si";
import { FaYoutube } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaCode } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { IoPersonAddSharp } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { FaInstagram } from "react-icons/fa6";
import { FiGlobe } from "react-icons/fi";






function HomePage() {
  const navigate = useNavigate();

  //theme
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const [ editMode, setEditMode ] = useState(false);
  function ToggleMode(){ toggleTheme(); }
  


  //data
  const [loading, setLoading] = useState(true);
  const [loadingTime, setLoadtingTime] = useState(true);
  const [profileData, setProfileData] = useState(null);

 
  //
  useEffect(() => {
    const fetchData = async () => {
      getProfileInfo();

      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1000);
      setLoadtingTime(false);
    };

    fetchData();
  }, []);
 


  //header info
  const headerInfo = [
    {"title": "Home", "id": "home"},
    {"title": "Project", "id":  "project"},
    {"title": "Contact",  "id": "contact"},
  ]


  const headerCallBack = (id) => {
    if (id === "reload") { 
      reloadPage();
    } else if (id === "home_page") { 
      window.location.href = "/profile"; 
    } else if (id === "home") { 
      scrollToElement(home_ref);
    } else if (id === "contact") {
      scrollToElement(contact_ref);
    } else if (id === "project") {
      scrollToElement(project_ref);
    }
  };
  


  const scrollToElement = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', easing: 'ease-in-out' });
  };
    
 
  function reloadPage() { window.location.reload(); }
  function openLink(link) { window.location.href = link; }
 

  




  //get profle and project
  function getProfileInfo() {
    setLoading(true);

    //header
    let headersList = {
      "x-api-key": "C#nS%!#l$k8MdasibVmE5U&f(a?",
      "Content-Type": "application/x-www-form-urlencoded",
    };

    //content
    let bodyContent = ``;

    //request details
    var requestOptions = {
      url: `${BackendCall}/profile`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
      withCredentials: true,
    };

    axios
      .request(requestOptions)
      .then((response) => {
        const jsonObject = response.data; 
 
        if (jsonObject.result_code === 1) { 
          setProfileData(jsonObject.data); 
        } 
        else { setProfileData(null); }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setProfileData(null);
        setLoading(false);
      });
  }



 

  //scrool to 
  const home_ref = useRef(null);
  const contact_ref = useRef(null);
  const project_ref = useRef(null);
  const scrollToTargetDiv = (ref) => {
    if (ref.current) { ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', easing: 'ease-in-out' }); }
  };
 
  



  return(
    <div className="flex flex-col h-screen">
      {(loading || loadingTime || profileData === null) ? ( <SplashPage /> ) 
      : (
        <div className="custom-scrollbar-p  flex justify-between bg-fj" >
          <Header logoInfo={"code"} info={headerInfo} callBack={(id) => headerCallBack(id)}/>
    
          <div ref={home_ref} className="w-full flex flex-col rounded-md mx-auto"  >

            {(isMobile) ? (
              <img src={Design1} alt="" className="fixed inset-0 w-80 h-80 scale top-[-10px] left-[-180px] transform z-10 scale-150"></img>
            ) : (
              <img src={Design1} alt="" className="fixed inset-0 w-80 h-80 top-[-100px] left-[-50px] transform z-10 scale-300"></img>
            )}
     
            {/* //section 1 */}
            <div className={` ${isMobile ? "flex-col px-1" : "flex-row  px-3"} flex h-200vh z-20 screen_container`} > 
    
    
              {/* /1 left */}
              <div className={`${isMobile ? "w-full px-3 mb-3" : "w-1/2 aside"} h-screen`} >
                <div className={`${isMobile ? "w-full " : "w-4/5 mb-[40px]"} absolute  bg-white shadow-lg mx-auto  bottom-0   left-0 right-0 rounded-3xl`}>
    
                  <div className="absolute bg-white shadow-lg h-48 w-48 m-auto inset-0 top-[-100%] rounded-full">
                    <img src={profileData.profile_icon} alt="Your Alt Text" className="p-1.5 h-full w-full  flex rounded-full object-cover"></img>
                  </div>
    
                  <div className="pt-30 flex items-center flex-col justify-between h-full">
    
                    <div className="flex flex-col items-center">
                      <span className="text-4xl font-bold flex justify-center" style={{ backgroundImage: 'linear-gradient(45deg, #3b82f6, #ec4899)', backgroundClip: 'text', WebkitBackgroundClip: 'text', color: 'transparent' }}>
                        {profileData.name}
                      </span>
                      <div className="p-3 flex flex-col items-center">
                        {profileData.jobTitles.map((title, index) => (
                          <span key={index} className="font-bold p-1 text-black text-opacity-70">{title}</span>
                        ))}
                      </div>
                    </div>
    
    
                    <div className="flex flex-row justify-between m-10">
    
                      <button className="button_1 flex flex-row items-center justify-center m-2" onClick={() => scrollToTargetDiv(project_ref)}>
                        <MdDashboard className="w-6 h-6" style={{ color: 'white' }} />
                        <span className=" font-bold px-2">Projects</span>
                      </button>
    
                      <button className="button_2 flex flex-row items-center justify-center m-2" onClick={() => scrollToTargetDiv(contact_ref)}>
                        <IoPersonAddSharp className="w-6 h-6" style={{ color: 'white' }} />
                        <span className=" font-bold px-2">Connect</span>
                      </button>
    
                    </div>
                  </div>
                </div>
              </div>
    
    
              {/* /1 right */}
              <div className={`${isMobile ? "w-full" : "w-1/2"}`}>
    
                {/* 1 */}
                <div className="h-screen flex flex-col items-end justify-center">
    
                  <div className="flex flex-row items-center py-4">
                    <span className=" font-bold text-5xl p-2">Bio</span>
                  </div>
                  
                  <div className="flex flex-row items-center text-end w-4/5">
                    <span className=" p-1">{profileData.bio}</span>
                  </div>
    
                </div>
    
                {/* 2 */}
                <div className="h-screen flex flex-col items-end justify-center" ref={contact_ref}>
    
                  <div className="flex flex-row items-center py-4">
                    <IoPerson  className="w-10 h-10" style={{color: 'white'}}/>
                    <span className=" font-bold text-5xl p-2">Contact</span>
                  </div>
                  
                  <div className="flex flex-row items-center">
                    <MdEmail  className="w-5 h-5" style={{color: 'white'}}/>
                    <span className=" font-bold p-1">Email: </span>
                    <a className="p-1" href={`mailto:${encodeURIComponent(profileData.emailId)}`}>
                      {profileData.emailId}
                    </a> 
                  </div>
    
                  <div className="flex flex-row items-center">
                    <FiGlobe  className="w-5 h-5" style={{color: 'white'}}/>
                    <span className=" font-bold p-1">Site: </span>
                    <span className=" p-1">{profileData.site}</span>
                  </div>
    
    
                  <span className="text-3xl font-bold  mt-7 mb-3">Links</span>
    
                  <div className="grid grid-cols-3 gap-4 justify-end">
    
                    {profileData.linkData.map((data, index) => (
                      <button key={index} onClick={() => openLink(data.link)}
                      className="layout_2_ h-22 w-22 flex flex-col items-center rounded-2xl justify-center p-1.5 my-2 ml-4 border border-white">
                         
                         {(data.icon === "FaInstagram") ? (
                          <FaInstagram className="w-10 h-10 m-2" style={{color: 'white'}}/>
                          ) : (data.icon === "FaYoutube") ? (
                           <FaYoutube className="w-10 h-10 m-2" style={{color: 'white'}}/>
                           ) : (data.icon === "CgNotes") ? (
                           <CgNotes className="w-10 h-10 m-2" style={{color: 'white'}}/>
                           ) :(data.icon === "FaGithub") ? (
                           <FaGithub className="w-10 h-10 m-2" style={{color: 'white'}}/>
                           ) :(data.icon === "SiLeetcode") ? (
                           <SiLeetcode className="w-10 h-10 m-2" style={{color: 'white'}}/>
                           ) : (data.icon === "FaLinkedin") ? (
                          <FaLinkedin className="w-10 h-10 m-2" style={{color: 'white'}}/>
                           ) : (<img src={data.icon} className="w-10 h-10 m-2" style={{color: 'white'}}></img>
                           )}

                        <span className=" font-bold px-2 text-sm">{data.title}</span>
                      </button>
                    ))}
                  </div>
                  
                </div>


                {/* 3 */}
                <div className="h-screen flex flex-col items-end justify-center">
    
                  <span className="text-3xl font-bold  mt-7 mb-3">Skill</span>
    
                  <div className="w-4/5 flex flex-row flex-wrap justify-end gap-2">
                    {profileData.skills.map((language, index) => (
                      <div key={index} className="layout_3_ flex justify-center text-center py-1 px-2 rounded-lg">
                        <span className=" font-bold text-md">{language}</span>
                      </div>
                    ))}
                  </div>
    
                </div>
    
              </div>
            </div>
    
    
     
            {/* //project list */}
            <div className="w-full" ref={project_ref}>
              {/* //project title */}
              <div className="w-full flex items-center justify-center">
                <span className="text-6xl font-bold pt-30">Projects</span>
              </div>

              {profileData.project_list.map((item, index) => (
                <AppDetails index={index} projectData={item} />
              ))}
            </div>


     
    
            <div className="w-full h-10" >
              <img src={Design2} alt="" className="w-full h-full"></img>
            </div>


            <div className="w-full min-h-96 bg-white flex flex-col items-center justify-center">
              <FaCode className="w-14 h-14 cursor-pointer" style={{ color: 'black' }} />
              <span className="text-5xl font-bold p-6" style={{ fontFamily : "cursive" , color: 'black'}}>Work With US</span>
              <button className="button_2 flex flex-row items-center justify-center m-2 px-4 py-2" onClick={() => scrollToTargetDiv(contact_ref)}>
                <IoPerson className="w-6 h-6" style={{ color: 'white' }} />
                <span className=" font-bold px-2">Connect</span>
                <FaArrowRight className="w-5 h-5" style={{ color: 'white' }}/>
              </button>
            </div>
    
            <div className="w-full h-10 rotate-180" >
              <img src={Design2} alt="" className="w-full h-full"></img>
            </div>
    
     
    
    
            <div className="screen_container h-70 m b-4 flex flex-col flex-start p-5 w-full" >
              <div className="flex flex-row items-center py-2">
                <IoPerson  className="w-7 h-7" style={{color: 'white'}}/>
                <span className=" font-bold text-2xl p-2">Contact</span>          
              </div>
                    
              <div className="flex flex-row items-center">
                <MdEmail  className="w-5 h-5" style={{color: 'white'}}/>
                <span className=" font-bold p-1">Email: </span>
                <a className="p-1" href={`mailto:${encodeURIComponent(profileData.emailId)}`}>
                  {profileData.emailId}
                </a>         
              </div>
    
              <div className="flex flex-row items-center">
                <FiGlobe  className="w-5 h-5" style={{color: 'white'}}/>
                <span className=" font-bold p-1">Site: </span>
                <span className=" p-1">{profileData.site}</span>
              </div>
            </div>
    
    
          </div>
    
        </div>
      )}
    </div>
  )
}


export default HomePage;