import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ThemeProvider from './ThemeProvider';

import './index.css';
import './build.css';
import './css/index.css';


//profile
import ProfilePage from './page/ProflePage';
import ProjectAll from './page/ProjectAll';
import ProjectInfo from './page/ProjectInfo';
 
import PageNotFound from './page/PageNotFound';



function App() {

  return (
    <ThemeProvider>
    <div className="App">
      <Router>
        <Routes>
          {/* //home profile */}
          <Route path="/" element={ <ProfilePage /> }/>
          <Route path="/home" element={ <ProfilePage /> }/>
          <Route path="/profile" element={ <ProfilePage /> }/>

          {/* //project */}
          <Route path="/project_info" element={ <ProjectInfo /> }/>

          <Route path="/project" element={ <ProjectAll tempInput={"all"}/> }/>
          <Route path="/project/android" element={ <ProjectAll tempInput={"android"}/> }/>
          <Route path="/project/web" element={ <ProjectAll tempInput={"web"}/> }/>



          <Route path="/ABLauncher" element={<ProjectInfo tempInput="com.ab.launcher" />} />
          <Route path="/DigialClockWidget" element={<ProjectInfo tempInput="com.abarts.widgetforu" />} />
          

          <Route path="*" element={ <PageNotFound /> }/>
        </Routes>
      </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;
