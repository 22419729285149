import React, { useRef, useState , useEffect} from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';



import '../index.css';
import Header from "../page_h/Header";
import AppDetails from "../page_h/AppDetails";
import { HomeURL, BackendCall } from "../s_data/file";



//icon adnd iamges
import Design1 from '../images/test_img_1.svg';
import SplashPage from "./SplashPage";
 



function ProjectInfo({tempInput}) {

  //param
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const inputProjectID = tempInput ? tempInput : params.get('id') ? params.get('id') : "null"
 

  //data
  const [loading, setLoading] = useState(true);
  const [loadingTime, setLoadtingTime] = useState(true);
  const [projectData, setProjectData] = useState(null);

 
  //
  useEffect(() => {
    const fetchData = async () => {

      getProjectInfo();
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1000);
      setLoadtingTime(false);
    };

    fetchData();
  }, []);



  //header info
  const headerInfo = [
    {"title": "All Project",  "id": "project"},
    {"title": "Contact",  "id": "contact"},
  ]



  const headerCallBack = (id) => {
    switch (id) {
      case "reload":
        window.location.reload();
        break;
      case "home_page":
      case "contact":
      case "project":
        window.location.href = "/"; 
        break;
      default:
        break;
    }
  };
 
  

  //get apps
  function getProjectInfo() {
    setLoading(true);

    let headersList = {
      "x-api-key": "C#nS%!#l$k8MdasibVmE5U&f(a?",
      "Content-Type": "application/x-www-form-urlencoded",
    };

    let bodyContent = `package_name=${inputProjectID}`;

    var requestOptions = {
      url: `${BackendCall}/project/info`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
      withCredentials: true,
    };

    axios
      .request(requestOptions)
      .then((response) => {
        const jsonObject = response.data; 
 
        if (jsonObject.result_code === 1) { 
          setProjectData(jsonObject.list); 
        } 
        else { 
          setProjectData(null); 
          window.location.href = "/";
        }
      
        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setProjectData(null);
        setLoading(false);
      });
  }


 
 
  
  return(
    <div>
    {(loading || loadingTime || projectData === null) ? ( <SplashPage /> ) : (

        <div className="custom-scrollbar bg-fj min-h-screen">
          <Header logoInfo={"logo"} info={headerInfo} callBack={(id) => headerCallBack(id)}/>

          {/* //style */}
          {(isMobile) ? ( <img src={Design1} alt="" className="fixed inset-0 w-80 h-80 z-10 scale top-[-10px] left-[-180px] transform  scale-150"></img>
          ) : ( <img src={Design1} alt="" className="fixed inset-0 w-80 h-80 z-10 top-[-100px] left-[-50px] transform scale-300"></img> )}

          {/* //empty div */}
          <div className="h-9"></div>

          <AppDetails index={0} projectData={projectData}/>

        </div>
      )}
    </div>
  )
}


export default ProjectInfo;