import React, { useState , useEffect} from "react";
 
import { isMobile } from 'react-device-detect';

import '../index.css';
import SplashPage from "./SplashPage";
 


function PageNotFound() {
  const [loadingTime, setLoadtingTime] = useState(true);

  useEffect(() => {
    const fetchData = async () => {

      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1000);
      setLoadtingTime(false);
    };

    fetchData();
  }, []);
 
 
  function goToHome() { window.location.replace("/"); }
  

  return(
    <div className="flex flex-col h-screen">
      {(loadingTime) ? ( <SplashPage /> ) 
      : (
        <div className="flex flex-col items-center justify-center pnf_bg bg-fj"> 

          <div className={`flex flex-col items-center  ${isMobile ? "scale-75" : "" }`}>
            <div className="font_1">404</div>
            <div className="font_2">Page Not Found</div>
          </div>


          <div className="m-10">
            <button className="button_1 px-4" onClick={goToHome}>Back to Home</button>
          </div>

        </div>
      )}
    </div>
  )
}


export default PageNotFound;