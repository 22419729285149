import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
 
const ImageSlider = ({images}) => {
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    const timer = setInterval(() => { setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); }, 5000);
    return () => clearInterval(timer);
  }, []);


  const nextImage = () => { setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); };
  const prevImage = () => { setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); };

  const sliderStyleMobile = {
    position: 'relative',
    width: '320px',
    height: '600px',
    margin: 'auto', 
    borderRadius: '20px',
  };

  const sliderStyleWeb = {
    position: 'relative',
    width: '620px',
    height: '400px',
    margin: 'auto', 
    borderRadius: '20px',
  };

  const sliderStyleAuto = {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    margin: 'auto 25px', 
    borderRadius: '20px',
  };

  const imageStyle = {
    width: '100%',
    height: '100%', 
    objectFit: 'cover',
    borderRadius: '20px',
  };


  const buttonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'white',
    color: 'black',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    textAlign: 'center',
    lineHeight: '20px',
    transition: 'all 0.3s',
    zIndex: 2,
  };

  const leftButtonStyle = {
    ...buttonStyle,
    left: '-20px',
  };

  const rightButtonStyle = {
    ...buttonStyle,
    right: '-20px',
  };

  const indicatorsStyle = {
    position: 'absolute',
    bottom: '10px',
    width: '100%',
    textAlign: 'center',
  };

  const indicatorStyle = {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    margin: '0 5px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  };

  const activeIndicatorStyle = {
    ...indicatorStyle,
    backgroundColor: '#717171',
  };



  return (
    <div 
      style={isMobile ? sliderStyleAuto : (images[0].device === "mobile" ? sliderStyleMobile : sliderStyleWeb)} 
      className='c-shadow'>
      
      <button style={leftButtonStyle} onClick={prevImage}> &#10094; </button>
      <img src={images[currentIndex].link} alt="slider" style={imageStyle} />
      <button style={rightButtonStyle} onClick={nextImage} > &#10095; </button>

      <div style={indicatorsStyle}>
        {images.map((_, index) => (
          <span className='c-shadow'
            key={index}
            style={index === currentIndex ? activeIndicatorStyle : indicatorStyle}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>


  );
};

export default ImageSlider;
