import React, { useState , useEffect, useContext } from 'react';


import { ThemeContext } from '../ThemeProvider';


//icon
import { FaCode } from "react-icons/fa6";
import { MdOutlineLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { isBrowser, isMobile } from 'react-device-detect';

import my_profile from '../images/my_profile.png';



  
function Header({logoInfo, info, callBack}) {

  //theme
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  function ToggleMode(){ toggleTheme(); }


 
  return(
    <div className={`${isMobile ? "px-2" : "px-3"} z-40 fixed top-0 right-0 left-0 backdrop-blur-sm`}>
      <div className="screen_container h-18 m-auto flex flex-row justify-between  items-center"> 

        {(logoInfo == "code") ? (
          <FaCode className="w-9 h-9 cursor-pointer mx-2" style={{ color: 'white' }} onClick={() => callBack("home_page")}/>
        ) : (
          <button className="flex flex-row items-center mx-3 cursor-pointer" onClick={() => callBack("home_page")}>
            <img src={my_profile} className="object-cover w-6 h-6 rounded-full"></img>
            {isBrowser && ( <span className="font-bold text-xl px-2 ">Amit Bodaliya</span> )}
          </button>
        )}

        <nav className='flex flex-row items-center'>
          <ul className="flex flex-row">
            {info.map((data, index) => (
              <li key={index} className="px-4 text-lg font-medium  hover:cursor-pointer">
                <button onClick={() => callBack(data.id)}>{data.title}</button>
              </li>
            ))}
          </ul>

          <div onClick={() => ToggleMode()} className='ml-5'>
            {(isDarkMode) ? ( <MdDarkMode className="h-5 w-6" style={{color: "white"}}/> ) 
            : ( <MdOutlineLightMode className="h-5 w-6" style={{color: "white"}}/> )}
            </div>
        </nav>

      </div>
    </div>
  )
}


export default Header;