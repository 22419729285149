import React from 'react';
 

//icon adnd iamges
import icon_play from "../images/icon_play.png";
import { MdDownload } from "react-icons/md"; 
import { FaLink } from "react-icons/fa6";


const AvalibleLink = ({type, link, name}) => { 


  function openLink() {
    if (link === "null") alert("Not available");
    else {
      if (type === "play_store" || type === "link") {
        window.open(link);
      } else if (type === "download") {
        const link = document.createElement('a');
        link.href = link;
        link.download = name + '.apk';
        link.click();
      }
    }
  }
 
 

  return (
    <div>
      {/* //download link */}
      <div className="hover:cursor-pointer my-5" onClick={() => openLink()}>
        {(type === "play_store") ? (
          <div className="flex flex-row items-center">
            <img className="h-12" src={icon_play} ></img>
          </div>
        ) : (type === "download") ? (
          <button className="button_1 px-5 py-3 flex flex-row items-center justify-center" >
            <MdDownload className="w-6 h-6" style={{ color: 'white' }} />
            <span className=" font-bold px-2">Download APK</span>
          </button> 
        ) : (type === "link") ? (
          <button className="button_1 px-5 py-3 flex flex-row items-center justify-center" >
            <FaLink className="w-6 h-6" style={{ color: 'white' }} />
            <span className=" font-bold px-2">Open Link</span>
          </button> 
        ) : (<div></div>
        )}
      </div>
    </div>
 
  );
};

export default AvalibleLink;
