// const MAIN_DOMAIN = "https://prop.amitbodaliya.com";
// const HomeURL = "http://192.168.211.212:3000";
// const BackendCall = "http://192.168.211.212:3030";

// const BackendCall = "http://localhost:3030";
// const HomeURL = "http://localhost:3000"

const BackendCall = "https://www.amitbodaliya.com";
const HomeURL = "https://www.amitbodaliya.com"


const DETAILS = {
  full_title: "Amit Bodaliya | Profile",
  title: " | Amit Bodaliya",
  site_ulr: "www.amitbodaliya.com",
  phone_no: "+91 8570983776",
  email_id: "amitbodaliyadev@gmail.com",
}



module.exports = { HomeURL, BackendCall, DETAILS };
